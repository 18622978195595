<script>
	
	let content = false

	const getGeoAd = async () => {
		try {
			let response = await fetch(`https://geocontent-ad.homehardware.workers.dev/`)
			content = await response.json()
		} catch (error) {
			console.log('ERROR getGeoAd:', error)
			content = false
		}
	}

	getGeoAd()

</script>

<svelte:options tag="geo-ad" />

{#if content}
<h3>Location-Based Advertisement</h3>
<pre>{JSON.stringify(content, null, 3)}</pre>
{/if}